import React from 'react';
import AppConfigurationService from '../../../services/app-configuration-service/app-configuration-service';
import { EU_CHAT_BOT_URLS } from '../../../constants';

interface NewEuHelpRouterProps {
    currentLanguageRegionCode: string;
}
export const NewEuHelpRouter = (props: NewEuHelpRouterProps) => {
    const appConfig = new AppConfigurationService();
    const countryCode = appConfig.currentCountryCode;
    const languageRegionCode = props.currentLanguageRegionCode.replace(
        '-',
        '_'
    );
    const checkIsProd =
        process.env.REACT_APP_ACTIVE_PROFILE &&
        process.env.REACT_APP_ACTIVE_PROFILE === 'prod';

    const prodUrl = () => {
        return checkIsProd
            ? '/content/guxeu/${countryCode}/${languageRegionCode}/configuration/dse-app-configuration/dse-chatbot-configuration.model.json'
            : `${EU_CHAT_BOT_URLS.DSE_CHAT_BOT_CONFIGURATION}/content/guxeu/${countryCode}/${languageRegionCode}/configuration/dse-app-configuration/dse-chatbot-configuration.model.json`;
    };

    return (
        <>
            <div
                className="host-container dse-chatbot"
                data-json-path={prodUrl()}
                data-is-author-edit-mode="false"
            />
        </>
    );
};
