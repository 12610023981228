import React, { useEffect, useState } from 'react';
import { scriptService } from './services/script-service/script-service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Routes, { findByAlias } from './routes';
import Layout from './layout/layout';
import ServerContext from './contexts/serverContext';
import AuthenticationService from './services/authentication-service/authentication-service';
import {
    BRAND,
    EU_COUNTRIES,
    SYNDICATE_UTIL_COUNTRIES,
    SYNDICATED_HEADER_COUNTRIES,
} from './constants';
import AppConfigurationService from './services/app-configuration-service/app-configuration-service';
import { useHistory, useLocation } from 'react-router-dom';
import ProfileService from './services/profile-service/profile-service';
import ExternalSiteNotableUtil from './components/utils/external-site-notable-util/external-site-notable-util';
import ClientContext, {
    ClientContextInterface,
} from './contexts/clientContext';
import { ProfileWithVehiclesResponse } from './models/profile-with-vehicles';
import loadable from '@loadable/component';
import serverSideService from './services/server-side-service/server-side-service';
import { consentService } from './services/consent-service/consent-service';
import { GDPRValues } from './models/gdpr';
import './App.scss';
import GlobalMXandEUFooter from './components/sections/footer/global-mx-eu-footer/global-mx-eu-footer';
import { DataLayerService } from './services/data-layer-service/data-layer-service';
import {
    lincolnModalRedirectPuertoRico,
    lincolnModalRedirectMexico,
    remapBrandSiteLinks,
} from './components/utils/syndicate-util/syndicate-util';
import EuHelpRouter from './components/sections/eu-help-router/eu-help-router';
import SecondaryNav from './components/sections/secondary-nav/secondary-nav';

const Header = loadable(() => import('./components/sections/header/header'));
const EuHeader = loadable(() =>
    import('./components/sections/eu-header/eu-header')
);
const Footer = loadable(() => import('./components/sections/footer/footer'));
const GdprBanner = loadable(() =>
    import('./components/sections/gdpr-banner/gdpr-banner')
);
const LincolnNAHeader = loadable(() =>
    import('./components/sections/header/lincoln-na-header/lincoln-na-header')
);
const TermsAndConditionsModal = loadable(() =>
    import(
        './components/sections/terms-and-conditions-modal/terms-and-conditions-modal'
    )
);
const ExternalSiteNotable = loadable(() =>
    import('./components/sections/external-site-notable/external-site-notable')
);
interface Props {
    serverContext: any;
}

function App(props: Props) {
    const context = props.serverContext;
    const isWebview = context.webview as boolean;
    const currentAppConfiguration = new AppConfigurationService().getAppConfiguration(
        context.domain,
        context.root || '/',
        undefined,
        context.brand,
        context.currentLanguageRegionCode
    );
    const [brand, language, country] = [
        context.brand ? context.brand : currentAppConfiguration.brand,
        context.currentLanguageRegionCode
            ? context.currentLanguageRegionCode
            : currentAppConfiguration.languageRegionCode,
        context.currentRegionCode
            ? context.currentRegionCode
            : currentAppConfiguration.countryCode,
    ];
    if (!context.current3LetterCountryCode) {
        context.current3LetterCountryCode =
            currentAppConfiguration.threeLetterCountryCode;
    }
    const fmaRegion = currentAppConfiguration.fmaRegion;
    const cookieConfiguration: GDPRValues = consentService.getGDPRCookieValue();
    const history = useHistory();
    const authenticationService = new AuthenticationService();
    if (context && !context.root) context.root = currentAppConfiguration.root;

    const location = useLocation();

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [href, setHref] = useState<string>('');
    const externalSiteNotableUtil = new ExternalSiteNotableUtil();
    const [unMount, setUnMount] = useState<boolean>(true);
    const [currentAnchor, setCurrentAnchor] = useState<any>();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    if (
        serverSideService.isClientSide() &&
        !localStorage.getItem('gdprPerformanceStatus') &&
        fmaRegion === 'eu'
    ) {
        localStorage.setItem('gdprPerformanceStatus', 'true');
    }
    if (
        serverSideService.isClientSide() &&
        !localStorage.getItem('gdprFunctionalStatus') &&
        fmaRegion === 'eu'
    ) {
        localStorage.setItem('gdprFunctionalStatus', 'true');
    }
    if (
        serverSideService.isClientSide() &&
        !localStorage.getItem('ccpaConsentValue')
    ) {
        localStorage.setItem(
            'ccpaConsentValue',
            consentService.nonEssentialScriptsAllowed().toString()
        );
    }
    const displayModal = (href: string): void => {
        if (
            externalSiteNotableUtil.regionUsesModal() &&
            externalSiteNotableUtil.hasExternalLink(href)
        ) {
            setIsModalVisible(true);
        }
        return;
    };

    function focusPreviousHref() {
        currentAnchor?.focus();
    }

    function setAnchor(anchorEle: any) {
        setCurrentAnchor(anchorEle);
    }

    const closeModal = (): void => {
        setIsModalVisible(false);
        focusPreviousHref();
    };

    const unMountExternalNotableModal = (): boolean => {
        setUnMount(true);
        return unMount;
    };

    const onAnchorClick = (event: Event) => {
        const anchorEle = externalSiteNotableUtil.getParentByTag(
            event.target,
            'A'
        );
        if (
            anchorEle &&
            externalSiteNotableUtil.enableNotableOption(anchorEle.href)
        ) {
            setAnchor(anchorEle);
            if (anchorEle.getAttribute('data-prevent-default') !== 'false') {
                event.preventDefault();
            }
            displayModal && displayModal(anchorEle.href);
            setHref(anchorEle.href);
            setUnMount(false);
        }
    };

    useEffect(() => {
        if (externalSiteNotableUtil.regionUsesModal()) {
            document.addEventListener('click', onAnchorClick, false);
            return () => {
                document.removeEventListener('click', onAnchorClick, false);
            };
        }
    });

    const mountExternalSiteNotableModal = () => {
        let notableComp = (
            <ExternalSiteNotable
                ctaPath={href}
                target={'_blank'}
                isVisible={isModalVisible}
                onClose={closeModal}
                unMount={unMountExternalNotableModal}
            />
        );
        if (unMount) {
            notableComp = <></>;
        }

        return notableComp;
    };

    const syndicatedHeaderFooterLoaded = () => {
        return (
            process.env.NODE_ENV === 'production' &&
            SYNDICATED_HEADER_COUNTRIES.includes(context.currentRegionCode)
        );
    };

    useEffect(() => {
        if (fmaRegion === 'eu') {
            const gdprTimer = setInterval(function() {
                const cookieValue: GDPRValues = consentService.getGDPRCookieValue();
                const performanceTagValue = localStorage.getItem(
                    'gdprPerformanceStatus'
                );
                const functionalTagValue = localStorage.getItem(
                    'gdprFunctionalStatus'
                );
                if (cookieValue) {
                    if (
                        fmaRegion === 'eu' &&
                        !cookieValue?.performance &&
                        performanceTagValue === 'true'
                    ) {
                        localStorage.setItem('gdprPerformanceStatus', 'false');
                        scriptService.loadDynatraceTags(true);
                        scriptService.setMouseflowScript(true);
                        clearInterval(gdprTimer);
                        window.location.reload();
                    } else if (
                        fmaRegion === 'eu' &&
                        cookieValue?.performance &&
                        performanceTagValue === 'false'
                    ) {
                        localStorage.setItem('gdprPerformanceStatus', 'true');
                        scriptService.loadDynatraceTags();
                        scriptService.setMouseflowScript();
                    }
                    if (
                        fmaRegion === 'eu' &&
                        !cookieValue?.functionality &&
                        functionalTagValue === 'true'
                    ) {
                        localStorage.setItem('gdprFunctionalStatus', 'false');
                        scriptService.loadCafexScript(true);
                        clearInterval(gdprTimer);
                        window.location.reload();
                    } else if (
                        fmaRegion === 'eu' &&
                        cookieValue?.functionality &&
                        functionalTagValue === 'false'
                    ) {
                        localStorage.setItem('gdprFunctionalStatus', 'true');
                        scriptService.loadCafexScript();
                    }
                }
            }, 10000);
        }
        if (
            country === 'us' &&
            !location.pathname.includes(
                findByAlias('ProTrailerBackupAssist')?.replaceAll('/', '')
            )
        ) {
            scriptService.loadChatBot();
        }
        if (country === 'us') {
            const ccpaTimer = setInterval(function() {
                const ccpaConsentStatus = localStorage.getItem(
                    'ccpaConsentValue'
                );
                const nonEssentialScriptConsent = consentService
                    .nonEssentialScriptsAllowed()
                    .toString();
                if (ccpaConsentStatus !== nonEssentialScriptConsent) {
                    localStorage.setItem(
                        'ccpaConsentValue',
                        nonEssentialScriptConsent
                    );

                    clearInterval(ccpaTimer);
                    window.location.reload();
                }
            }, 10000);
        }
    }, []);

    useEffect(() => {
        const resizeSyndicatedHeaderNavBar = () => {
            const headerNav = document.querySelector(
                `.global-owner-syndicated-${brand}-header nav.navbar.navbar-default.navbar-static-top`
            ) as HTMLElement;
            if (headerNav) {
                headerNav.style.maxWidth = `${document.body.clientWidth}px`;
            }
        };
        const syndicatedSignOutHandler = () => {
            sessionStorage.clear();
        };
        const signOutLiElement = document.querySelector(
            'li.fma-signout'
        ) as HTMLLIElement | null;
        const authSignOutLinks = signOutLiElement?.querySelectorAll(
            'a.auth_singout, a.auth_signout'
        ) as NodeListOf<HTMLAnchorElement>;
        const syndicatedSignOut = document.querySelectorAll(
            'a[href*="#$userSignOut"]'
        ) as NodeListOf<HTMLAnchorElement>;
        if (
            fmaRegion !== 'eu' ||
            (fmaRegion === 'eu' && cookieConfiguration?.performance)
        ) {
            scriptService.loadDynatraceTags();
            scriptService.setMouseflowScript();
        }

        DataLayerService.populatePersistentProperties().then(
            scriptService.loadAnalyticsScript
        );

        if (country === 'us') {
            scriptService.loadOneTrustCookieBanner();
        }
        if (country === 'ca') {
            scriptService.loadEvidonCookieBanner();
        }
        if (
            fmaRegion !== 'eu' ||
            (fmaRegion === 'eu' && cookieConfiguration?.functionality)
        ) {
            scriptService.loadCafexScript();
        }
        if (syndicatedHeaderFooterLoaded()) {
            syndicatedSignOut &&
                syndicatedSignOut.forEach(node =>
                    node.addEventListener('click', syndicatedSignOutHandler)
                );
            authSignOutLinks?.forEach(node => {
                node.addEventListener('click', syndicatedSignOutHandler);
            });
            resizeSyndicatedHeaderNavBar();
            window.addEventListener('resize', resizeSyndicatedHeaderNavBar);
        }

        if (
            serverSideService.isClientSide() &&
            process.env.NODE_ENV !== 'production'
        ) {
            if (!(window as any).fma) {
                console.debug(
                    'App.tsx - going to initialize FMA in local non SSR environment--------------'
                );
                scriptService.loadFMA(context.applicationId);
            }
        }

        scriptService.loadSophusScript();
        return () => {
            syndicatedSignOut &&
                syndicatedSignOut.forEach(node =>
                    node.removeEventListener('click', syndicatedSignOutHandler)
                );
            authSignOutLinks?.forEach(node =>
                node.removeEventListener('click', syndicatedSignOutHandler)
            );
            window.removeEventListener('resize', resizeSyndicatedHeaderNavBar);
        };
    }, [language]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isOriginUrl = () => {
        return (
            currentAppConfiguration.brand &&
            currentAppConfiguration.languageRegionCode &&
            currentAppConfiguration.root ===
                `/${currentAppConfiguration.brand}/${currentAppConfiguration.languageRegionCode}/`
        );
    };

    useEffect(() => {
        if (location.search.includes('script')) {
            history.push('/');
        }
        authenticationService.onIsAuthenticated().then(authenticated => {
            // authenticationService.setFmaRedirectUrl(
            //     location.pathname === '/' &&
            //         (process.env.REACT_APP_B2C_FLAG === 'false' ||
            //             !isOriginUrl())
            //         ? window.location.href.endsWith('/')
            //             ? window.location.href.concat(
            //                   findByAlias('VehicleDashboardView').replace(
            //                       '/',
            //                       ''
            //                   )
            //               )
            //             : window.location.href.concat(
            //                   findByAlias('VehicleDashboardView')
            //               )
            //         : window.location.href
            // );

            // try setting state across all markets
            !authenticationService.getFmaState() &&
                authenticationService.setFmaState();

            if (
                authenticated &&
                !sessionStorage.getItem('postLoginNavComplete')
            ) {
                // if (location.pathname === '/')
                //     history.push(findByAlias('VehicleDashboardView'));
                if (authenticationService.getFmaState()) {
                    const state = authenticationService.getFmaState();
                    // if (
                    //     currentAppConfiguration.domain &&
                    //     currentAppConfiguration.root?.length > 1
                    // ) {
                    //     history.push(
                    //         state?.startsWith('/') ? state : `/${state}`
                    //     );
                    // } else {
                    //     let redirectBase = window.location.href;
                    //     if (redirectBase.endsWith('/')) {
                    //         const lastIndexOfSlash = redirectBase.lastIndexOf(
                    //             '/'
                    //         );
                    //         redirectBase = redirectBase.substring(
                    //             0,
                    //             lastIndexOfSlash
                    //         );
                    //     }
                    //     window.location.assign(redirectBase + state);
                    // }
                    history.push(state?.startsWith('/') ? state : `/${state}`);
                } else if (!authenticationService.getFmaState()) {
                    // the page is loaded after being authenticated in a different session
                    console.log(location.pathname);
                    if (location.pathname === '/')
                        history.push(findByAlias('VehicleDashboardView'));
                    // window.location.href =
                    //     window.location.origin +
                    //     context.root +
                    //     findByAlias('VehicleDashboardView').replace(
                    //         '/',
                    //         ''
                    //     );
                }
                sessionStorage.setItem('postLoginNavComplete', 'true');
            }
        });
        if (SYNDICATE_UTIL_COUNTRIES.includes(country)) {
            remapBrandSiteLinks();
        }
        if (brand === 'lincoln') {
            if (country === 'pr') {
                lincolnModalRedirectPuertoRico();
            } else if (country === 'mx') {
                lincolnModalRedirectMexico();
            }
        }
    }, [location]);

    const [clientContext, setClientContext] = useState<ClientContextInterface>({
        setProfile: (profile: ProfileWithVehiclesResponse) => {
            setClientContext({ ...clientContext, profile: profile });
        },
    });

    useEffect(() => {
        authenticationService.onIsAuthenticated().then(authenticated => {
            if (authenticated) {
                new ProfileService().request().then(profile => {
                    clientContext.setProfile(profile);
                    const ownLastLoggedInUser = localStorage.getItem(
                        'ownLastLoggedInUser'
                    );
                    if (
                        !ownLastLoggedInUser ||
                        ownLastLoggedInUser !== profile.profile.email
                    ) {
                        localStorage.setItem(
                            'ownUserFirstTimeLogin',
                            new Date().getTime().toString()
                        );
                        localStorage.setItem(
                            'ownLastLoggedInUser',
                            profile.profile.email
                        );
                        localStorage.setItem(
                            'ownLastLoggedInUserFname',
                            profile.profile.firstName
                        );
                    }
                });
                setIsAuthenticated(authenticated);
            }
        });
    }, []);
    const isLincolnBrand = () =>
        brand === BRAND.lincoln.LONG_NAME ||
        brand === BRAND.lincolndemo.LONG_NAME;

    const getBrandHeader = () => {
        if (syndicatedHeaderFooterLoaded() || isWebview) return <></>;
        else {
            let headerRenderer = <Header />;
            if (EU_COUNTRIES.includes(country)) {
                headerRenderer = <EuHeader />;
            } else if (isLincolnBrand()) {
                headerRenderer = <LincolnNAHeader />;
            }
            return headerRenderer;
        }
    };
    const getBrandFooter = () => {
        if (EU_COUNTRIES.includes(country) && isWebview) {
            return <></>;
        } else {
            if (EU_COUNTRIES.includes(country)) {
                return <GlobalMXandEUFooter />;
            } else if (syndicatedHeaderFooterLoaded()) {
                return <></>;
            } else return <Footer />;
        }
    };

    const loadGDPRBanner = () => {
        return EU_COUNTRIES.includes(country);
    };
    const navigateToSection = (id?: string) => {
        const element = document.getElementById(
            id || location.hash?.replace('#', '')
        );
        element && window.scrollTo(element.offsetLeft, element.offsetTop - 120);
    };
    const onAppLoad = () => {
        navigateToSection();
        document.querySelectorAll('.section-heading-link').forEach(link =>
            link.addEventListener('click', () =>
                setTimeout(() => {
                    navigateToSection(
                        (link as HTMLAnchorElement).href.split('#')[1]
                    );
                })
            )
        );
    };

    const styleBrand = () => {
        if (brand === 'demo') return 'ford';
        else if (brand === 'lincolndemo') return 'lincoln';
        else return brand;
    };

    return (
        <>
            <ServerContext.Provider value={context}>
                <ClientContext.Provider value={clientContext}>
                    <div onLoad={onAppLoad} className={`brand-${styleBrand()}`}>
                        {getBrandHeader()}
                        {country === 'us' &&
                            styleBrand() === 'ford' &&
                            isAuthenticated && <SecondaryNav />}
                        {!isWebview && fmaRegion === 'eu' && <EuHelpRouter />}
                        <div className="main-overlay"></div>
                        <Layout>
                            <Routes />
                        </Layout>
                        {getBrandFooter()}
                        {loadGDPRBanner() && <GdprBanner />}
                        {!location.pathname.includes('yfa-') && (
                            <TermsAndConditionsModal />
                        )}
                        {mountExternalSiteNotableModal()}
                    </div>
                </ClientContext.Provider>
            </ServerContext.Provider>
        </>
    );
}

export default App;
