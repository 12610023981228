import { useContext, useEffect, useRef, useState } from 'react';
import { SecondaryNavQuickLinks } from './quick-link';
import VehicleTypeService from '../../../services/vehicle-type-service/vehicle-type-service';
import './secondary-nav.scss';
import ClientContext from '../../../contexts/clientContext';
import ProfileService from '../../../services/profile-service/profile-service';
import ServerContext from '../../../contexts/serverContext';
import { useSecondarNavContent } from './hooks/use-secondary-nav-content';
import { ViewportUtil } from '../../utils/viewport-util/viewport-util';
import UserIcon from '../../../assets/user-icon.svg';
import CaretRight from '../../../assets/right-arrow.svg';
import CaretDown from '../../../assets/arrow-down.svg';
import { useVehiclePageContent } from '../vehicle-portal/hooks/use-vehicle-page-content';
import { goToMyAccount } from '../../../views/vehicle-dashboard-view/vehicle-dashboard-view';
import React from 'react';

interface AemContentItem {
    title: string;
    link: string;
    isConnected: boolean;
    category: string;
    connected: boolean;
}

const SecondaryNav = () => {
    const {
        currentLanguageRegionCode,
        current3LetterCountryCode,
        profile,
        webviewData,
    } = useContext(ServerContext);
    const userSelectedVin =
        typeof window === 'undefined'
            ? profile?.vin
            : window.sessionStorage.getItem('selectedVin');
    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [selectedVin, setSelectedVin] = useState<string | null>(
        webviewData?.vin || userSelectedVin
    );
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [categoryContent, setCategoryContent] = useState<any>(null);
    const [zIndex, setZIndex] = useState<number>(1001);
    const clientContext = useContext(ClientContext);
    const profileService = new ProfileService();
    const { isMobileView } = ViewportUtil();
    const vehiclePageContent = useVehiclePageContent();
    const wrapperRef = useRef<HTMLDivElement>(null);

    const content = useSecondarNavContent();
    const {
        navList,
        shortcutsLabel,
        viewAllLabel,
        profileName,
        showSecondaryNav,
    } = content?.secondary_nav ?? {};
    const firstName = clientContext?.profile?.profile?.firstName;

    useEffect(() => {
        const handleStorageChange = () => {
            setSelectedVin(sessionStorage.getItem('selectedVin'));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [sessionStorage.getItem('selectedVin')]);

    useEffect(() => {
        let filteredContent = null;
        // If connected vehicle, show all items
        // If the vehicle is not connected, show only the items that are not connected
        if (isConnected) {
            filteredContent = navList;
        } else {
            filteredContent = navList?.filter(
                (item: AemContentItem) => item.isConnected === false
            );
        }
        const groupedByCategory = filteredContent?.reduce(
            (acc: Record<string, AemContentItem[]>, item: AemContentItem) => {
                // Check if the category already exists in the accumulator
                if (!acc[item.category]) {
                    acc[item.category] = [];
                }
                // Push the current item to the corresponding category array
                acc[item.category].push(item);
                return acc;
            },
            {} as Record<string, AemContentItem[]>
        );

        // Convert the grouped object into an array of objects
        const groupedArray =
            groupedByCategory &&
            Object.keys(groupedByCategory)?.map(category => ({
                category,
                items: groupedByCategory[category],
            }));

        setCategoryContent(groupedArray);
    }, [isConnected, content]);

    const checkConnectedVehicle = async (vin: string) => {
        const { isConnected } = await new VehicleTypeService().request(
            vin,
            currentLanguageRegionCode,
            current3LetterCountryCode?.toUpperCase()
        );
        setIsConnected(isConnected ?? false);
    };

    useEffect(() => {
        const userEmail = clientContext?.profile?.profile?.email;
        const vehiclesArray = clientContext?.profile?.vehicles ?? [];
        const userPreferences = userEmail
            ? profileService.getUserPreferences(userEmail)
            : null;

        // this will be the most recent model year on the vehicle
        // const mostRecentVehicle = vehiclesArray.reduce((latest, vehicle) => {
        //     return vehicle.modelYear > (latest?.modelYear || 0)
        //         ? vehicle
        //         : latest;
        // }, vehiclesArray[0]);

        const selectedVin =
            // mostRecentVehicle?.vin ||
            sessionStorage.getItem('selectedVin') ||
            userPreferences?.lastSelectedVin ||
            vehiclesArray[0]?.vin ||
            null;
        setSelectedVin(selectedVin);

        selectedVin && checkConnectedVehicle(selectedVin);
    }, [clientContext, selectedVin]);

    const handleExpandClick = () => {
        setIsExpanded(prev => !prev);
        setSelectedVin(sessionStorage.getItem('selectedVin'));
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target as Node)
        ) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const desktopElement = document.querySelector(
                '.main-item.flyout-toggle.fgx-btn.fgx-brand-container-pad-mbl.open'
            );
            const mobileElement = document.querySelector(
                '.fgx-navbar-collapse.collapse.menu-items-container.opened'
            );
            const globalNav = document.querySelector(
                '.fordMainNavigationR23'
            ) as HTMLElement;

            if (globalNav) {
                if (
                    document.documentElement.scrollTop >= 56 ||
                    document.body.scrollTop >= 56
                ) {
                    globalNav.style.opacity = '0.0001';
                } else {
                    globalNav.style.opacity = '1';
                }
            }

            if (desktopElement || mobileElement) {
                setZIndex(-1);
            } else if (window.scrollY === 0) {
                setZIndex(1001);
            } else {
                setZIndex(99999);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const expandedContainerRef = useRef<HTMLDivElement>(null);

    const generateExpandedClass = (): string => {
        return isExpanded ? 'is-expanded' : '';
    };

    const generateConnectedClass = (): string => {
        return isConnected ? 'is-connected' : 'not-connected';
    };

    return (
        content &&
        showSecondaryNav && (
            <div
                style={{
                    position: 'sticky',
                    top: '0',
                    zIndex: zIndex,
                }}
            >
                <div
                    ref={wrapperRef}
                    className={`secondary-nav-main-wrapper ${generateExpandedClass()}`}
                >
                    <div
                        className="title-and-control-button"
                        onClick={handleExpandClick}
                    >
                        <div className="my-shortcuts">{shortcutsLabel}</div>
                        <div
                            className={`view-all__toggle-container ${generateExpandedClass()}`}
                        >
                            <span className="view-all__label">
                                {viewAllLabel}
                            </span>
                            <img
                                src={CaretDown}
                                alt="caret-down"
                                className={`caret-down ${
                                    isExpanded ? 'rotate' : ''
                                }`}
                            />
                        </div>
                    </div>
                    <div
                        className={`expanded-container ${generateExpandedClass()}`}
                        data-testid={
                            isExpanded
                                ? 'expanded-container-open'
                                : 'expanded-container-closed'
                        }
                        ref={expandedContainerRef}
                    >
                        <div
                            className={`expanded-content ${generateConnectedClass()}`}
                        >
                            {categoryContent && (
                                <>
                                    {isMobileView && (
                                        <div
                                            className="profile-info"
                                            onClick={() =>
                                                goToMyAccount(
                                                    vehiclePageContent
                                                )
                                            }
                                        >
                                            <img
                                                src={UserIcon}
                                                alt="profile-icon"
                                            />
                                            <span className="first-name">
                                                {profileName.replace(
                                                    '##firstName##',
                                                    firstName
                                                )}
                                            </span>
                                            <img
                                                src={CaretRight}
                                                alt="caret-right"
                                            />
                                        </div>
                                    )}
                                    {Object.keys(categoryContent).map(
                                        (item, index) => (
                                            <React.Fragment key={item}>
                                                <SecondaryNavQuickLinks
                                                    category={
                                                        categoryContent[item]
                                                            .category
                                                    }
                                                    links={
                                                        categoryContent[item]
                                                            .items
                                                    }
                                                    vin={selectedVin || ''}
                                                />
                                                {isMobileView &&
                                                    index <
                                                        Object.keys(
                                                            categoryContent
                                                        ).length -
                                                            1 && (
                                                        <hr className="horizontal-divider" />
                                                    )}
                                            </React.Fragment>
                                        )
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default SecondaryNav;
